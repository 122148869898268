<template>
    <app-overlay
        :class="`${this.formClass}__confirmation-overlay`"
        :show-overlay="showOverlay"
    >
        <app-spinner v-if="processing"></app-spinner>

        <div
            v-else
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
        >
            <b-modal v-model="showOverlay" hide-footer hide-header>
                <p id="form-confirm-label">{{ title }}</p>
                <p v-if="description !== ''">
                    <span class="confirmation-description">{{ description }}</span>
                </p>
                <div class="d-flex justify-content-center">
                    <b-button :variant="confirmVariant"  @click="onConfirm">{{
                        confirmText
                    }}</b-button>
                    <b-button
                        :variant="cancelVariant"
                        class="ml-3"
                        @click="onCancel"
                    >
                        {{ $t('forms.buttons.cancel') }}
                    </b-button>
                </div>
            </b-modal>
        </div>
    </app-overlay>
</template>

<script>
    import AppOverlay from './AppOverlay.vue';
    import AppSpinner from './AppSpinner.vue';

    export default {
        components: { AppOverlay, AppSpinner },
        name: 'AppModalConfirmation',
        props: {
            title: {
                type: String,
                default: 'Title',
            },
            description: {
                type: String,
                default: '',
            },
            formClass: {
                type: String,
                default: 'app-form',
            },
            processing: {
                type: Boolean,
                default: false,
            },
            showOverlay: {
                type: Boolean,
                default: false,
            },
            cancelVariant: {
                type: String,
                default: 'light',
            },
            confirmVariant: {
                type: String,
                default: 'info',
            },
            confirmButtonText: {
                type: String,
                default: null,
            },
        },
        computed: {
            confirmText() {
                return this.confirmButtonText || this.$t('forms.buttons.confirm');
            },
        },
        methods: {
            onCancel() {
                this.$emit('was-cancelled');
            },
            onConfirm() {
                this.$emit('was-confirmed');
            },
        },
    };
</script>
