<template>
    <div :class="`${this.formClass}__form-container form-container`">
        <div v-if="title !== ''" class="form-title__container">
            <h3 :class="`${this.formClass}__title form-title`">
                {{ title }}
            </h3>
            <slot name="title-notification"></slot>
        </div>

        <b-form :class="`${this.formClass}__form form-body`" @submit="submit">
            <slot name="fields"></slot>

            <div :class="`${this.formClass}__buttons form-buttons`">
                <slot name="buttons"></slot>
            </div>
        </b-form>

        <app-modal-confirmation
            v-if="needsConfirmation"
            :title="confirmationTitle"
            :description="confirmationDescription"
            :processing="processing"
            :form-class="formClass"
            :show-overlay="showOverlay"
            @was-cancelled="$emit('was-cancelled')"
            @was-confirmed="submit"
        ></app-modal-confirmation>

        <!-- <app-alert
            variant="danger"
            :message="errorMessage"
            v-if="hasError === true"
            @alert-dismissed="alertDismissed"
        ></app-alert>
        <app-alert
            variant="success"
            :message="successMessage"
            v-if="hasError === false"
            @alert-dismissed="alertDismissed"
        ></app-alert> -->
    </div>
</template>

<script>
    // import AppAlert from './AppAlert.vue';
    import AppModalConfirmation from './AppModalConfirmation.vue';

    export default {
        components: { AppModalConfirmation },
        name: 'AppForm',
        data() {
            return {
                processing: false,
                hasError: null,
                errorMessage: '',
            };
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            formClass: {
                type: String,
                default: 'app-form',
            },
            onSubmit: {
                type: Function,
                default: () => true,
            },
            successMessage: {
                type: String,
                default: 'Success',
            },
            needsConfirmation: {
                type: Boolean,
                default: true,
            },
            confirmationTitle: {
                type: String,
                default: '',
            },
            confirmationDescription: {
                type: String,
                default: '',
            },
            showOverlay: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            alertDismissed() {
                this.hasError = null;
            },
            async submit(event) {
                if (event !== undefined) {
                    event.preventDefault();
                }

                this.processing = true;

                try {
                    await this.onSubmit();

                    this.hasError = false;
                } catch (error) {
                    this.errorMessage = error.message;
                    this.hasError = true;
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>
