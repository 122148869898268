<template>
    <b-form-group
        :id="`${id}-form-group`"
        class="app-input-container"
        :label-for="id"
        :label="label"
        :description="description"
    >
        <b-form-select
            v-if="type === 'select'"
            v-model="value"
            class="form-control app-input"
            :class="inputClass"
            :options="options"
            :required="required"
            @input="updateParentValue"
        ></b-form-select>

        <vue-tel-input
            v-else-if="type === 'tel'"
            variant="primary"
            :class="{ 'is-invalid': isValid === false }"
            mode="international"
            input-classes="form-control app-input"
            :id="id"
            v-model="value"
            :placeholder="placeholder"
            @country-changed="(e) => this.$emit('country-changed', e)"
            :validCharactersOnly="true"
            :enabledCountryCode="true"
            :required="required"
            @input="updateParentValue"
        ></vue-tel-input>

        <b-form-textarea
            v-else-if="type === 'description'"
            id="textarea"
            v-model="value"
            :placeholder="placeholder"
            rows="4"
            max-rows="8"
            :required="required"
            @input="updateParentValue"
        ></b-form-textarea>

        <b-form-input
            v-else
            :id="id"
            class="app-input"
            :class="inputClass"
            :type="inputType"
            :name="name"
            v-model="value"
            :placeholder="placeholder"
            :state="isValid"
            @input="updateParentValue"
            :required="required"
            :plaintext="plaintext"
        ></b-form-input>

        <b-input-group-append
            v-if="name === 'password'"
            class="app-input-icon append clickable"
            @click="isPasswordVisible = !isPasswordVisible"
        >
            <b-icon v-if="isPasswordVisible" icon="eye-fill"></b-icon>
            <b-icon v-else icon="eye-slash-fill"></b-icon>
        </b-input-group-append>

        <b-input-group-append
            v-if="name === 'search'"
            class="app-input-icon append clickable"
        >
            <b-icon
                icon="search"
                @click="iconClicked"
                class="app-input-icon--search"
            ></b-icon>
        </b-input-group-append>

        <b-form-invalid-feedback :id="`${id}-live-feedback`">
            {{ invalidFeedback }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
    export default {
        name: 'AppInput',
        data() {
            return {
                value: this.initialValue,
                isPasswordVisible: false,
            };
        },
        props: {
            label: {
                type: String,
                default: '',
            },
            id: {
                type: String,
                default: 'app-input',
            },
            name: {
                type: String,
                default: 'app-input',
            },
            type: {
                type: String,
                default: 'text',
            },
            description: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            options: {
                type: Array,
                default() {
                    return [];
                },
            },
            initialValue: {
                type: [String, Number],
                default: '',
            },
            isValid: {
                type: Boolean,
                default: null,
            },
            required: {
                type: Boolean,
                default: null,
            },
            invalidFeedback: {
                type: String,
                default: 'Error',
            },
            plaintext: {
                type: Boolean,
                default: false,
            },
            colouredBackground: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            inputType() {
                if (this.name === 'password') {
                    return this.isPasswordVisible ? 'text' : 'password';
                }

                return this.type;
            },
            inputClass() {
                const bgColour = this.colouredBackground ? 'bg-coloured' : '';
                const isPlainText = this.plaintext ? 'plain-text' : '';
                return `${this.name}-input ${isPlainText} ${bgColour}`;
            },
        },
        methods: {
            updateParentValue(e) {
                this.$emit('update-value', e);
            },
            iconClicked(e) {
                this.$emit('icon-clicked', e);
            },
        },
    };
</script>
